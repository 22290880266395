<template src="./CompanyForm.html"></template>
<style scoped lang="scss" src="./CompanyForm.scss"></style>

<script>
import { ServiceFactory } from "@/services";
import { TypeMasterData } from "@/constants"
const MasterManagementService = ServiceFactory.get('master')
const UserService = ServiceFactory.get('user')

export default {
  name: 'CompanyForm',
  props: {
    isLoading: {
      require: false,
      type: Boolean,
      default: false
    },
    userData: {},
  },
  watch: {
    userData(value) {
      this.formData.brandId = value.brandId || '';
      this.formData.email = value.email || '';
      this.formData.savedFolder = value.savedFolder || '';
      this.onCancel()
    },
  },
  data() {
    return {
      isCreated: false,
      formData: {
       brandId: '',
       email: '',
       savedFolder: '',
       isSendMail: false,
      },
      brandOptions: [],
      rules: {
        brandId: [
          { required: true, message: '必須項目です。', trigger: 'change' },
        ],
        email: [
          { message: '必須項目です。', trigger: 'blur' },
          { type: 'email', message: 'メールアドレスは無効です。', trigger: ['blur', 'change'] }
        ],
        savedFolder: [
          { message: '必須項目です。', trigger: 'blur' },
          { max: 1000, message: 'フォルダ保存先1000文字を超えてはなりません。', trigger: ['blur', 'change'] }
        ],
      },
    };
  },
  created() {
    this.getBrandOptions();
  },
  methods: {
    onCancel() {
     this.$refs.form.resetFields()
    },

    async onSubmit() {

      const isValid = await this.$refs.form.validate()

      if (!isValid) return

      this.$emit('submit', this.formData)
    },

    async getBrandOptions() {
      this.brandOptions = await this.getOptionListItems(TypeMasterData.brand)
    },

    async getOptionListItems(type) {
      const items = await MasterManagementService.getItemsList({ type }).catch(() => ([]))
      return items || []
    },

    async getDetailCompany() {
      const detailCompany = await UserService.getBrandById(this.formData.brandId).catch(() => ({}))
      const isExistEditField = detailCompany && detailCompany.email || detailCompany && detailCompany.savedFolder

      if (isExistEditField) {
        this.formData.email = detailCompany.email
        this.formData.savedFolder = detailCompany.savedFolder
        this.isCreated = false
      } else {
        this.formData.email = ''
        this.formData.savedFolder = ''
        this.isCreated = true
      }
    },

  }
}
</script>
